<template>
  <v-layout style="margin: 0px; padding: 0px; width: 100%;">
    <v-row>
      <v-col col="12" md="4">
        <v-row wrap>
          <v-col cols="8" offset="2">
            <v-row>
              <v-col cols="10">
                <router-link to="/" style="text-decoration: none;color: black;">
                  <v-icon>mdi-arrow-left-circle-outline</v-icon>
                  <span class="ml-5"> Return to Home </span>
                </router-link>
              </v-col>
            </v-row>

            <v-row style="margin-top:30px;">
              <v-col cols="12" align="center">
                <router-link to="/">
                  <v-img
                    class="centerImg2"
                    :src="'img/logo.png'"
                  />
                </router-link>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" align="center" justify="center">
                <span class="titleStyleColor">Admin</span>
                <span class="titleStyle"> Signin</span>
              </v-col>
            </v-row>

            <v-row>
              <v-progress-linear
                color="#7CBF46"
                v-show="loader"
                :indeterminate="true"
              ></v-progress-linear>
              <v-col cols="12">
                <v-row>
                  <v-text-field
                    v-model="login.email"
                    label="Admin Email"
                    append-icon="mdi-email"
                    outlined
                    dense
                    color="#7CBF46"
                  ></v-text-field>
                </v-row>

                <v-row>
                  <v-text-field
                    name="password"
                    label="Password"
                    outlined
                    :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[rules.required, rules.min]"
                    :type="show3 ? 'text' : 'password'"
                    id="password"
                    dense
                    v-model="login.password"
                    @click:append="show3 = !show3"
                    color="#7CBF46"
                  ></v-text-field>
                </v-row>
                <!-- 
                <v-layout wrap>
                  <v-switch v-model="checkbox" color="#72bd61">
                    <template v-slot:label>
                      <div>
                        remember login
                      </div>
                    </template>
                  </v-switch>
                </v-layout>
                -->

                <v-layout color="#BD2121">
                  <h5 class="text-danger" align="center">
                    {{ errorMessage }}
                  </h5>
                </v-layout>

                <v-layout>
                  <v-btn
                    align="center"
                    class="buttonStyle"
                    @click.native="signin"
                    block
                  >
                    Sign In
                  </v-btn>
                </v-layout>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col col="12" md="8" class="loginPadding hidden-sm-and-down">
        <div class="overlay"></div>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
export default {
  name: "Login",

  data: () => ({
    loader: false,
    checkbox: false,
    errorMessage: "",
    show3: false,
    login: {
      email: "",
      password: "",
    },
    rules: {
      required: (value) => !!value || "Required.",
      min: (v) => v.length >= 8 || "Min 8 characters",
    },
  }),
  methods: {
    signin() {
      this.loader = true;
      this.errorMessage = "";
      this.$http
        .post(`${process.env.VUE_APP_URL}login`,this.login,{
          headers: {
            'apiKey':`${process.env.VUE_APP_KEY}`
          }
        })
        .then((response) => {
          if (response.data.success == true) {
            this.loader = false;
            localStorage.setItem("userId", response.data.data);
            this.$router.push({
              path: "/code/verification/" + this.login.email,
            });
          }
        })
        .catch((error) => {
          this.loader = false;
          this.errorMessage = error.response.data.data;
        });
    },
  },
};
</script>

<style scoped>
.overlay {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
}

.loginPadding {
  margin: 0px !important;
  padding: 0px !important;
  background: url("https://res.cloudinary.com/ecrtechdev/image/upload/v1632382207/development-tile-ad_2_x777h4.png")
    no-repeat center right fixed !important;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100vh;
}

.titleStyle {
  width: 411px;
  font-family: ABeeZee;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
}

.titleStyleColor {
  width: 411px;
  font-family: ABeeZee;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  background: -webkit-linear-gradient(#72bd61, #111212);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.centerImg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 20%;
  text-align: center;
}

.centerImg2 {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 40%;
  text-align: center;
}

.buttonStyle {
  width: auto;
  height: auto;
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 14px;
}
</style>
